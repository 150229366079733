import React from 'react';

type Props = {
  className?: string;
  btnText: string;
};

const ModalButton = ({ className, btnText }: Props) => {
  return (
    <button
      className={`btn w-100 ${className}`}
      type={btnText === 'Verifying' || btnText === 'Success!' ? 'button' : 'submit'}
    >
      {btnText}
    </button>
  );
};

export default ModalButton;
