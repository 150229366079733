import React from 'react';
import ReactDOM from 'react-dom';

import { TwoFactorAuthModal } from '../modals/TwoFactorAuthModal';

const twoFactorAuth = document.getElementsByClassName(
  'js-two-factor-auth-container',
)[0] as HTMLElement;

if (twoFactorAuth) {
  ReactDOM.render(<TwoFactorAuthModal />, twoFactorAuth);
}
