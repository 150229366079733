import React, { useEffect, useState, ClipboardEvent } from 'react';

import { ModalButton, OtpInput } from './components';

type Props = {
  intent: string;
  otp: string;
  btnText: string;
  closeModal: (e: React.SyntheticEvent) => void;
  onChange: (value: string) => void;
  submitHandler: (e?: React.SyntheticEvent) => void;
  onClick: (e: React.SyntheticEvent) => void;
};

export const VerifyTwoFactorModal = ({
  intent,
  otp,
  btnText,
  closeModal,
  onChange,
  submitHandler,
  onClick,
}: Props) => {
  const [bodyText, setBodyText] = useState('');

  const otpPasteHandler = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.clipboardData) {
      const pastedOtp = e.clipboardData.getData('Text').replace(/\D/g, '');
      onChange(pastedOtp.substring(0, 6));
    }
  };

  useEffect(() => {
    if (intent === 'disable') {
      setBodyText('disable Two Factor Authentication');
    }
    if (intent === 'change-email') {
      setBodyText('change your email');
    }
    if (intent === 'change-password') {
      setBodyText('change your password');
    }
    if (intent === 'sell-artwork') {
      setBodyText('sell this artwork');
    }
    if (intent === 'mint-artwork') {
      setBodyText('mint this artwork');
    }
    if (intent === 'mint-artwork') {
      setBodyText('change-payout-details');
    }
    if (intent === 'sign-in') {
      setBodyText('Enter the six digit code from your Authenticator to complete logging in.');
    }
  }, []);

  return (
    <>
      <div className='modal-header modal-header--two-factor-auth'>
        <h2 className='modal-title'>Enter your 2FA code</h2>
        <button className='modal-close' onClick={closeModal}></button>
      </div>
      <div className='modal-body'>
        <p className='modal-body--faint mb-1'>
          {intent !== 'sign-in'
            ? `To ${bodyText}, enter the six digit code from your Authenticator.`
            : bodyText}
        </p>
        <form
          className={`modal-body--center otp-form w-100 ${
            intent === 'sell' ? 'sell-otp-btn' : null
          }`}
          onSubmit={submitHandler}
        >
          <OtpInput
            otp={otp}
            otpLength={6}
            onChange={onChange}
            onPaste={otpPasteHandler}
            submitHandler={submitHandler}
          />
          <ModalButton className='bg-cta' btnText={btnText} />
        </form>
        {intent === 'sign-in' || intent === 'disable' ? (
          <p className='modal-body--faint mt-1 otp-text--recover' onClick={onClick}>
            Use recovery code
          </p>
        ) : null}
      </div>
    </>
  );
};
