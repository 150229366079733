import React, { useEffect, useState } from 'react';

import { ComposingTooltip } from '../components/UI/ComposingTooltip';
import { ModalButton } from './components';

type Props = {
  recoveryCodes: string[];
  submitHandler: (e: React.SyntheticEvent) => void;
  checkBox: () => void;
  isBoxChecked: boolean;
};

export const RecoveryCodesModal = ({
  recoveryCodes,
  submitHandler,
  checkBox,
  isBoxChecked,
}: Props) => {
  const [formattedRecoveryCodes, setFormattedRecoveryCodes] = useState('');
  const [codesCopied, setCodesCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(formattedRecoveryCodes).then(() => {
      setCodesCopied(true);
      setTimeout(() => setCodesCopied(false), 2000);
    });
  };

  useEffect(() => {
    setFormattedRecoveryCodes(recoveryCodes.join('\n'));
  }, [recoveryCodes]);

  return (
    <>
      <h2 className='modal-header modal-title'>Recovery codes</h2>
      <div className='modal-body'>
        <p className='modal-body--faint'>
          Please save your recovery codes. You will use a recovery code in case you enter a one-time
          password from your authenticator incorrectly 5 times. If you lose access to these codes
          you might not be able to recover your account.
        </p>
        <p className='pr-1 modal-body__columns'>{formattedRecoveryCodes}</p>
        <ComposingTooltip
          trigger='manual'
          visible={codesCopied}
          delay={100}
          title='Recovery codes copied to the clipboard'
        >
          <div className='modal-body--center'>
            <button className='btn btn--border-light w-100 mb-1' onClick={copyToClipboard}>
              Copy codes to clipboard
            </button>
          </div>
        </ComposingTooltip>
        <form className='w-100 mb-0 ' onSubmit={submitHandler}>
          <label
            className='mb-1 checkbox__input checkbox__input--modal'
            htmlFor='recovery-check'
            onClick={checkBox}
          >
            <input
              className='modal-checkbox'
              name='recovery-check'
              type='checkbox'
              onChange={checkBox}
              checked={isBoxChecked}
              required
            />
            &nbsp;&nbsp;I confirm that I saved my recovery codes
          </label>
          <ModalButton
            className={isBoxChecked ? 'bg-cta' : 'disabled-modal-button'}
            btnText={'Close'}
          />
        </form>
      </div>
    </>
  );
};
