import React from 'react';

import { ModalButton, OtpKeyCode } from './components';

type Props = {
  qrCode: string;
  keyCode: string;
  enableType: string;
  closeModal: (e: React.SyntheticEvent) => void;
  btnText: string;
  submitHandler: (e: React.SyntheticEvent) => void;
  otp: string;
  onlyNumbers: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const QrCodeModal = ({
  qrCode,
  keyCode,
  enableType,
  closeModal,
  btnText,
  submitHandler,
  otp,
  onlyNumbers,
}: Props) => {
  return (
    <>
      <div className='modal-header modal-header--two-factor-auth'>
        <h2 className='modal-title'>{`Enable Authenticator${
          enableType === 'sell' ? ' before trading' : enableType === 'mint' ? ' before minting' : ''
        }`}</h2>
        <button className='modal-close' onClick={closeModal}></button>
      </div>
      <div className='modal-body'>
        {document.getElementsByClassName('js-otp-verification--sell')[0] ? (
          <p className='modal-body--faint'>
            You must enable Two Factor Authentication in order to sell your artwork.
          </p>
        ) : null}
        <p className='modal-body--faint'>
          Scan the QR-Code or enter the key in your authentication app such as Google Authenticator,
          Authy or others.
        </p>
        <div className='modal-body--center'>
          <img src={qrCode} />
          <OtpKeyCode keyCode={keyCode} />
          <form className='modal-body--center w-100' onSubmit={submitHandler}>
            <input
              className='w-100 modal-input'
              name='otp'
              type='text'
              inputMode='numeric'
              autoComplete='one-time-code'
              value={otp}
              maxLength={6}
              onChange={onlyNumbers}
              placeholder='6 digit code from authenticator app'
              required
            />
            <ModalButton className='bg-cta' btnText={btnText} />
          </form>
        </div>
      </div>
    </>
  );
};
