import React, { useEffect } from 'react';

import { ModalButton } from './components';

type Props = {
  code: string;
  btnText: string;
  closeModal: (e: React.SyntheticEvent) => void;
  submitHandler: (e: React.SyntheticEvent) => void;
  onChange: (value: string) => void;
};

export const RecoverAccountModal = ({
  code,
  btnText,
  closeModal,
  submitHandler,
  onChange,
}: Props) => {
  useEffect(() => {}, []);

  return (
    <>
      <div className='modal-header modal-header--two-factor-auth'>
        <h2 className='modal-title'>Enter recovery code</h2>
        <button className='modal-close' onClick={closeModal}></button>
      </div>
      <div className='modal-body'>
        <p className='modal-body--faint mb-1'>
          Enter one of the recovery codes which were provided when you registered your Two Factor
          Authentication.
        </p>
        <form className='modal-body--center otp-form w-100' onSubmit={submitHandler}>
          <input
            className='w-100 mb-2'
            name='recovery'
            type='text'
            onChange={(e: { target: { value: string } }) => {
              onChange(e.target.value);
            }}
            value={code}
            placeholder='Recovery code'
            required
          />
          <ModalButton className='bg-cta mt-1' btnText={btnText} />
        </form>
      </div>
    </>
  );
};
