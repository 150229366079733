import React, { useEffect, useState } from 'react';

import { request } from 'utils/request';

import { EnableTwoFactorAuth } from './EnableTwoFactorAuth';
import { VerifyTwoFactorModal } from './VerifyTwoFactorModal';
import { RecoverAccountModal } from './RecoverAccountModal';

import { Alert } from '../components/UI/Alert';

const enableBtn = document.getElementsByClassName(
  'js-enable-two-factor-auth-btn',
)[0] as HTMLElement;
const disableBtn = document.getElementsByClassName(
  'js-disable-two-factor-auth-btn',
)[0] as HTMLElement;
const deleteDisableBtn = document.getElementsByClassName(
  'js-disable-two-factor-auth-del-btn',
)[0] as HTMLElement;
const changeEmailBtn = document.getElementsByClassName(
  'js-otp-verification--email',
)[0] as HTMLElement;
const changePasswordBtn = document.getElementsByClassName(
  'js-otp-verification--password',
)[0] as HTMLElement;
const changePayoutDetailsBtn = document.getElementsByClassName(
  'js-otp-verification--payout-details',
)[0] as HTMLElement;
const signInPage = document.getElementsByClassName('js-sign-in-page')[0] as HTMLElement;

type Props = {
  openModal?: boolean;
  intent?: string;
  enableType?: string;
  onSuccess?: () => void;
  onClose?: () => void;
};

export const TwoFactorAuthModal = (props: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [intent, _setIntent] = useState<string>('');
  const [qrCode, setQrCode] = useState<string>('');
  const [keyCode, setKeyCode] = useState<string>('');
  const [enableType, setEnableType] = useState<string>(props.enableType || '');
  const [recovCodesList, _setRecovCodesList] = useState<string[]>([]);
  const [alertText, setAlertText] = useState<string>('');
  const [btnText, setBtnText] = useState<string>('OK');
  const [otp, setOtp] = useState<string>('');
  const [isRecovBoxChecked, _setIsRecovBoxChecked] = useState<boolean>(false);
  const [isRecovModal, _setIsRecovModal] = useState<boolean>(false);
  const [recovCode, setRecovCode] = useState<string>('');

  const getQrCode = () => {
    request.get(`/api/internal/otp_secrets/new`).then(({ data }) => {
      if (data) {
        setQrCode(data.qr);
        setKeyCode(data.key);
        setModalOpen(true);
      }
    });
  };

  const postRequest = (url: string, payload: object, successFunc?: () => void) => {
    setAlertText('');
    setBtnText('Verifying');
    request
      .post(url, payload)
      .then(({ data }) => {
        setBtnText('Success!');
        if (data.recovery_codes) enableOtpSuccess(data.recovery_codes);
        else if (successFunc) successFunc();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (isRecovModal) {
            setAlertText(
              `The recovery code was incorrect. \n Please try again or use another code.`,
            );
            setRecovCode('');
          } else if (error.response.data.attemptsLeft === 0) {
            setIsRecovModal(true);
            setAlertText(
              `You entered the incorrect code 5 times. \n Please enter one of your recovery codes.`,
            );
          } else {
            setAlertText(`The code expired or was incorrect. \n Please try again.`);
            const firstInput = document.getElementsByClassName('otp-digit')[0] as HTMLElement;
            if (firstInput) firstInput.focus();
          }
          setOtp('');
        } else {
          console.log(`Error message: ${error}`);
        }
        setBtnText('OK');
      });
  };

  // callback funcs for postRequest success
  const enableOtpSuccess = (recov_codes: Array<string>) => {
    setRecovCodesList(recov_codes);
    setOtp('');
  };

  const disableOtpSuccess = () => {
    setAlertText('You have successfully disabled Two Factor Authentication');
    setTimeout(() => {
      setModalOpen(false);
      setOtp('');
      location.reload();
    }, 2000);
  };

  const verifyOtpSuccess = () => {
    setTimeout(() => {
      setModalOpen(false);
      setOtp('');
      setBtnText('OK');
      if (intent === 'sign-in') {
        location.pathname = '/';
      } else if (intent === 'mint-artwork') {
        if (props.onSuccess && props.onClose) {
          props.onSuccess();
          props.onClose();
        }
      } else if (intent === 'sell-artwork') {
        const successEvent = new CustomEvent('twoFactorSuccess');
        window.dispatchEvent(successEvent);
        setModalOpen(false);
      } else {
        location.reload();
      }
    }, 2000);
  };

  const recovCodesSuccess = () => {
    if (intent === 'disable') {
      location.reload();
    } else {
      location.pathname = '/';
    }
  };

  const openOtpModal = (intent: string) => {
    setIntent(intent);
    if (!modalOpen) {
      if (intent === 'enable') {
        getQrCode();
      } else {
        setModalOpen(true);
      }
    }
  };

  const closeActions = (intent: string) => {
    if (intent === 'sign-in') {
      history.back();
    }
    if (props.onClose) {
      props.onClose();
    }
    setModalOpen(false);
    setAlertText('');
    setOtp('');
    setRecovCode('');
  };

  const closeModal = (e: React.SyntheticEvent) => {
    if (e.target === e.currentTarget) {
      // Prevents modal closing before user verifies they copied recovery codes
      if (!(recovCodesList.length !== 0 && !isRecovBoxChecked)) {
        closeActions(intent);
        if (isRecovBoxChecked) {
          location.reload();
        }
        if (isRecovModal) {
          setIsRecovModal(false);
        }
      }
    }
  };

  const escModal = (e: KeyboardEvent) => {
    // Prevents esc from closing modal before user verifies they copied recovery codes
    if (
      e.key === 'Escape' &&
      !(recovCodesListRef.current.length !== 0 && !isBoxCheckedRef.current)
    ) {
      e.preventDefault();
      closeActions(intent);
      if (isBoxCheckedRef.current) {
        location.reload();
      }
      if (isRecovModalRef.current) {
        setIsRecovModal(false);
      }
    }
  };

  // submit handlers
  const enableSubmitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    postRequest('/api/internal/otp_secrets', { otp });
  };

  const recovCodeListSubmitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isRecovBoxChecked) {
      setModalOpen(false);
      location.reload();
    }
  };

  // TODO: a specific :id is not required in below two routes for them to work
  // needs to be removed from otp_secrets_controller since they are redundant
  const otpSubmitHandler = (e?: React.SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (intent === 'disable') {
      postRequest('/api/internal/otp_secrets/disable_otp_api', { otp }, disableOtpSuccess);
    } else if (intent === 'sign-in') {
      postRequest(
        `/api/internal/otp_secrets/${signInPage.dataset.id}/verification_signin_api`,
        { otp, intent, rm: signInPage.dataset.rm },
        verifyOtpSuccess,
      );
    } else {
      postRequest(`/api/internal/otp_secrets/verification_api`, { otp, intent }, verifyOtpSuccess);
    }
  };

  const recovSubmitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (intent === 'sign-in') {
      postRequest(
        `/api/internal/otp_secrets/${signInPage.dataset.id}/account_recovery_code`,
        {
          otp_backup_code: recovCode,
          rm: signInPage.dataset.rm,
          intent,
        },
        recovCodesSuccess,
      );
    } else if (intent === 'disable') {
      postRequest(
        `/api/internal/otp_secrets/${disableBtn.dataset.id}/account_recovery_code`,
        {
          otp_backup_code: recovCode,
          intent,
        },
        recovCodesSuccess,
      );
    }
  };

  // useRef required to get current states
  const recovCodesListRef = React.useRef<string[]>(recovCodesList);
  const setRecovCodesList = (recov_codes: string[]) => {
    recovCodesListRef.current = recov_codes;
    _setRecovCodesList(recov_codes);
  };

  const isBoxCheckedRef = React.useRef<boolean>(isRecovBoxChecked);
  const setIsRecovBoxChecked = (data: boolean) => {
    isBoxCheckedRef.current = data;
    _setIsRecovBoxChecked(data);
  };

  const intentRef = React.useRef<string>(intent);
  const setIntent = (intent: string) => {
    intentRef.current = intent;
    _setIntent(intent);
  };

  const isRecovModalRef = React.useRef<boolean>(isRecovModal);
  const setIsRecovModal = (data: boolean) => {
    isRecovModalRef.current = data;
    _setIsRecovModal(data);
  };

  useEffect(() => {
    if (props.openModal) {
      if (intent === 'enable') {
        getQrCode();
      }
      if (intent === 'mint-artwork') {
        setModalOpen(true);
      }
    }
  }, [props.openModal]);

  useEffect(() => {
    if (props.intent) {
      setIntent(props.intent);
    }
  }, [props.intent]);

  useEffect(() => {
    if (otp.length === 6 && intent !== 'enable') {
      otpSubmitHandler();
    }
  }, [otp]);

  useEffect(() => {
    window.addEventListener('enableTwoFactorSell', () => {
      setEnableType('sell');
      openOtpModal('enable');
    });
    window.addEventListener('twoFactorAuthModalSell', () => openOtpModal('sell-artwork'));

    document.addEventListener('keydown', escModal);

    enableBtn ? enableBtn.addEventListener('click', () => openOtpModal('enable')) : null;
    disableBtn ? disableBtn.addEventListener('click', () => openOtpModal('disable')) : null;
    deleteDisableBtn
      ? deleteDisableBtn.addEventListener('click', () => openOtpModal('disable'))
      : null;
    changeEmailBtn
      ? changeEmailBtn.addEventListener('click', () => openOtpModal('change-email'))
      : null;
    changePasswordBtn
      ? changePasswordBtn.addEventListener('click', () => openOtpModal('change-password'))
      : null;
    changePayoutDetailsBtn
      ? changePayoutDetailsBtn.addEventListener('click', () => openOtpModal('change-payout-details'))
      : null;
    if (signInPage) {
      openOtpModal('sign-in');
    }
  }, []);

  if (!modalOpen) {
    return <></>;
  } else {
    return (
      <div className='modal-backdrop' onClick={closeModal}>
        {alertText !== '' ? (
          <Alert
            className='alert--modal'
            children={alertText}
            variant={
              alertText === 'You have successfully disabled Two Factor Authentication'
                ? 'info'
                : 'error'
            }
          />
        ) : null}
        <div className='modal modal--narrow'>
          {intent === 'enable' ? (
            <EnableTwoFactorAuth
              qrCode={qrCode}
              keyCode={keyCode}
              enableType={enableType}
              closeModal={closeModal}
              btnText={btnText}
              qrSubmitHandler={enableSubmitHandler}
              otp={otp}
              onlyNumbers={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOtp(e.target.value.replace(/\D/g, ''))
              }
              recoveryCodesList={recovCodesList}
              recoverySubmitHandler={recovCodeListSubmitHandler}
              checkBox={() => {
                setIsRecovBoxChecked(!isRecovBoxChecked);
              }}
              isBoxChecked={isRecovBoxChecked}
            />
          ) : null}
          {(intent === 'disable' ||
            intent === 'change-email' ||
            intent === 'change-password' ||
            intent === 'sell-artwork' ||
            intent === 'mint-artwork' ||
            intent === 'change-payout-details' ||
            signInPage) &&
          !isRecovModal ? (
            <VerifyTwoFactorModal
              intent={intent}
              closeModal={closeModal}
              btnText={btnText}
              submitHandler={otpSubmitHandler}
              otp={otp}
              onChange={(value: string) => setOtp(value)}
              onClick={() => setIsRecovModal(true)}
            />
          ) : null}
          {isRecovModal ? (
            <RecoverAccountModal
              code={recovCode}
              btnText={btnText}
              closeModal={closeModal}
              submitHandler={recovSubmitHandler}
              onChange={(value: string) => setRecovCode(value)}
            />
          ) : null}
        </div>
      </div>
    );
  }
};
