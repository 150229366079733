// Much of this code came from:
// https://dominicarrojado.com/posts/how-to-create-your-own-otp-input-in-react-and-typescript-with-tests-part-1/
import React, { ClipboardEvent, useMemo } from 'react';

type Props = {
  otp: string;
  otpLength: number;
  onChange: (value: string) => void;
  onPaste: (e: ClipboardEvent<HTMLInputElement>) => void;
  submitHandler: (e?: React.SyntheticEvent) => void;
};

const OtpInput = ({ otp, otpLength, onChange, onPaste, submitHandler }: Props) => {
  const re = new RegExp(/^\d+$/);

  const otpValues = useMemo(() => {
    const otpArray = otp.split('');
    const values: string[] = [];
    for (let i = 0; i < otpLength; i++) {
      const char = otpArray[i];
      if (re.test(char)) {
        values.push(char);
      } else {
        values.push('');
      }
    }
    return values;
  }, [otp, otpLength]);

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;
    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;
    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = re.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetOtpLength = targetValue.length;

    if (targetOtpLength === 1) {
      const newValue = otp.substring(0, idx) + targetValue + otp.substring(idx + 1);
      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }
      focusToNextInput(target);
    } else if (targetOtpLength === otpLength) {
      onChange(targetValue);
      target.blur();
    }
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || target.value !== '') {
      return;
    }

    focusToPrevInput(target);
  };

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    // keep focusing back until previous input
    // element has value
    const prevInputEl = target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div className='w-100 otp-group'>
      {otpValues.map((digit, idx) => (
        <input
          key={idx}
          className='w-100 otp-digit'
          name='otp'
          type='text'
          inputMode='numeric'
          autoComplete='one-time-code'
          maxLength={otpLength}
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
          autoFocus={idx === 0 ? true : false}
          onPaste={onPaste}
          required
        />
      ))}
    </div>
  );
};

export default OtpInput;
