import React, { useState } from 'react';

import { ComposingTooltip } from 'components/UI/ComposingTooltip';

const OtpKeyCode = ({ keyCode }: { keyCode: string }) => {
  const [isOtpCopied, setIsOtpCopied] = useState<boolean>(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(keyCode).then(() => {
      setIsOtpCopied(true);
      setTimeout(() => setIsOtpCopied(false), 2000);
    });
  };

  return (
    <div className='modal-body--center mt-1 mb-1'>
      <ComposingTooltip
        trigger='manual'
        visible={isOtpCopied}
        delay={100}
        title='OTP copied to the clipboard'
      >
        {
          <div className='d-flex modal__copy-text' onClick={copyToClipboard}>
            <p className='pr-1 mb-0'>Key: {keyCode}</p>
            <ComposingTooltip title='Click to copy'>
              <span className='icon-copy-text' />
            </ComposingTooltip>
          </div>
        }
      </ComposingTooltip>
    </div>
  );
};

export default OtpKeyCode;
