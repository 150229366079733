import React from 'react';

import { QrCodeModal } from './QrCodeModal';
import { RecoveryCodesModal } from './RecoveryCodesModal';

type Props = {
  qrCode: string;
  keyCode: string;
  enableType: string;
  closeModal: (e: React.SyntheticEvent) => void;
  btnText: string;
  qrSubmitHandler: (e: React.SyntheticEvent) => void;
  recoverySubmitHandler: (e: React.SyntheticEvent) => void;
  otp: string;
  onlyNumbers: (e: React.ChangeEvent<HTMLInputElement>) => void;
  recoveryCodesList: string[];
  checkBox: () => void;
  isBoxChecked: boolean;
};

export const EnableTwoFactorAuth = ({
  qrCode,
  keyCode,
  enableType,
  closeModal,
  btnText,
  qrSubmitHandler,
  recoverySubmitHandler,
  otp,
  onlyNumbers,
  recoveryCodesList,
  checkBox,
  isBoxChecked,
}: Props) => {
  return (
    <>
      {recoveryCodesList.length === 0 ? (
        <QrCodeModal
          qrCode={qrCode}
          keyCode={keyCode}
          enableType={enableType}
          closeModal={closeModal}
          btnText={btnText}
          submitHandler={qrSubmitHandler}
          otp={otp}
          onlyNumbers={onlyNumbers}
        />
      ) : (
        <RecoveryCodesModal
          recoveryCodes={recoveryCodesList}
          submitHandler={recoverySubmitHandler}
          checkBox={checkBox}
          isBoxChecked={isBoxChecked}
        />
      )}
    </>
  );
};
